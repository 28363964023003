<template>
  <div class="bubbleBox">
    <div ref="Info" class="Bubble">
      <div v-if="!item.geometryType" class="markbox">
        <div v-if="item.equipmentType === 'warn'" class="mainBox">
          <div class="markTitle">
            <p>
              <span>{{ item.title }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form
                class="blueFormLabelClass"
                label-position="right"
                label-width="100px"
                :inline="true"
                style="width:580px"
              >
                <el-form-item label="所属企业名称:">
                  <div class="equipment-item">
                    {{ item.companyName }}
                  </div>
                </el-form-item>
                <el-form-item label="设备名称:">
                  <div class="equipment-item">
                    {{ item.warningName }}
                  </div>
                </el-form-item>
                <el-form-item label="设备编号:">
                  <div class="equipment-item">
                    {{ item.warningCode }}
                  </div>
                </el-form-item>
                <el-form-item label="设备类型:">
                  <div class="equipment-item">
                    {{ item.title }}
                  </div>
                </el-form-item>
                <el-form-item label="设备条码:">
                  <div class="equipment-item">
                    {{ item.barCode }}
                  </div>
                </el-form-item>
                <el-form-item label="报警器类型:">
                  <div class="equipment-item">
                    {{ item.warningTypeName }}
                  </div>
                </el-form-item>
                <el-form-item label="分类一:">
                  <div class="equipment-item">
                    {{ item.warningKindName }}
                  </div>
                </el-form-item>
                <el-form-item label="规格:">
                  <div class="equipment-item">
                    {{ item.spec}}
                  </div>
                </el-form-item>
                <el-form-item label="型号:">
                  <div class="equipment-item">
                    {{ item.model }}
                  </div>
                </el-form-item>
                <el-form-item label="厂商:">
                  <div class="equipment-item">
                    {{ item.factory }}
                  </div>
                </el-form-item>
                <!-- <el-form-item label="权属单位:">
                  <div class="equipment-item">
                    {{ item.companyName }}
                  </div>
                </el-form-item> -->
                <el-form-item label="是否远传:">
                  <div class="equipment-item">
                    {{ item.transmitName }}
                  </div>
                </el-form-item>
                <el-form-item label="设备状态:">
                  <div class="equipment-item">
                    {{ item.generalStatusName }}
                  </div>
                </el-form-item>
                <el-form-item label="安装时间:">
                  <div class="equipment-item">
                    {{ item.installDate }}
                  </div>
                </el-form-item>
                <el-form-item label="是否在线:">
                  <div class="equipment-item">
                    {{ item.onlineName }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label=" ">
                  <div class="equipment-item"></div>
                </el-form-item>
                <el-form-item label="使用协议:">
                  <div class="equipment-item-textarea">
                    {{ item.useAccord }}
                  </div>
                </el-form-item>
                <el-table
                  v-if="item.list.length > 0"
                  :data="item.list"
                  style="width: 100%"
                  :header-cell-style="headerStyle"
                  :row-style="headerStyle"
                >
                  <el-table-column type="index" label="序号" width="50"> </el-table-column>
                  <el-table-column prop="cameraName" label="探头名称"> </el-table-column>
                  <el-table-column prop="remark" label="备注"> </el-table-column>
                </el-table>
              </el-form>
            </div>
            <div class="footer">
              <el-button type="primary" class="but" size="mini" @click="equipmentSave(item, 'warn')"
                >编 辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                class="but"
                @click="equipmentDel(item.id, 'warn')"
                >删 除</el-button
              >
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'camera'">
          <div class="markTitle">
            <p>
              <span>{{ item.title }}--{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form
                class="blueFormLabelClass"
                label-position="right"
                label-width="90px"
                :inline="true"
                style="width:560px"
              >
                <el-form-item label="设备编号:">
                  <div class="equipment-item">
                    {{ item.cameraCode }}
                  </div>
                </el-form-item>
                <el-form-item label="设备名称:">
                  <div class="equipment-item">
                    {{ item.cameraName }}
                  </div>
                </el-form-item>
                <el-form-item label="设备类型:">
                  <div class="equipment-item">
                    {{ item.title }}
                  </div>
                </el-form-item>
                <el-form-item label="设备条码:">
                  <div class="equipment-item">
                    {{ item.barCode }}
                  </div>
                </el-form-item>
                <el-form-item label="分类一:">
                  <div class="equipment-item">
                    {{ item.cameraKindName }}
                  </div>
                </el-form-item>
                <el-form-item label="规格:">
                  <div class="equipment-item">
                    {{ item.spec }}
                  </div>
                </el-form-item>
                <el-form-item label="厂商:">
                  <div class="equipment-item">
                    {{ item.factory }}
                  </div>
                </el-form-item>
                <el-form-item label="型号:">
                  <div class="equipment-item">
                    {{ item.model }}
                  </div>
                </el-form-item>
                <el-form-item label="权属单位:">
                  <div class="equipment-item">
                    {{ item.companyName }}
                  </div>
                </el-form-item>
                <el-form-item label="是否在线:">
                  <div class="equipment-item">
                    {{ item.onlineName }}
                  </div>
                </el-form-item>
                <el-form-item label="安装日期:">
                  <div class="equipment-item">
                    {{ item.installDate }}
                  </div>
                </el-form-item>
                <el-form-item label="设备状态:">
                  <div class="equipment-item">
                    {{ item.generalStatusName }}
                  </div>
                </el-form-item>
                <el-form-item label="是否远传:">
                  <div class="equipment-item">
                    {{ item.transmitName }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="使用协议:">
                  <div class="equipment-item-textarea">
                    {{ item.useAccord }}
                  </div>
                </el-form-item>
                <el-form-item label="服务器地址:">
                  <div class="equipment-item-textarea">
                    {{ item.serveUrl }}
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer">
              <el-button
                type="primary"
                class="but"
                size="mini"
                @click="equipmentSave(item, 'camera')"
                >编 辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                class="but"
                @click="equipmentDel(item.id, 'camera')"
                >删 除</el-button
              >
            </div>
          </div>
        </div>
        <!-- <div v-if="item.equipmentType === 'userSta' || item.equipmentType === 'station'">
          <div class="markTitle">
            <p>
              <span>{{ item.title }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass" label-position="right" label-width="80px">
                <el-form-item label="名称:">
                  <div class="equipment-item">
                    {{ item.stationName }}
                  </div>
                </el-form-item>
                <el-form-item label="场站分类:">
                  <div class="equipment-item">
                    {{ item.stationKindName }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="备注:">
                  <div class="equipment-item">
                    {{ item.remark }}
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div> -->
        <div v-if="item.equipmentType === 'line'">
          <div class="markTitle">
            <p>
              <span>{{item.pipeName}}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-pipe">
            <div class="contentList">
              <el-form
                class="blueFormLabelClass"
                label-position="right"
                :inline="true"
                style="width:540px"
                label-width="62px"
              >
              <el-form-item label="管道名称:">
                  <div class="equipment-item-textarea">{{ item.pipeName }}</div>
                </el-form-item>
                <el-form-item label="所属图层:">
                  <div class="equipment-item-textarea">{{ item.layerName }}</div>
                </el-form-item>
                <el-form-item label="管道编号:">
                  <div class="equipment-item-textarea">{{ item.pipeCode }}</div>
                </el-form-item>
                
                <el-form-item >
                  <label slot="label">管材:</label>
                  <div class="equipment-item">{{ item.pipeTypeName }}</div>
                </el-form-item>
                <el-form-item label="管材型号:">
                  <div class="equipment-item">{{ item.pipeModelName }}</div>
                </el-form-item>
                
                <el-form-item label="公称直径:">
                  <div class="equipment-item">{{ item.diameterName }}</div>
                </el-form-item>
                <el-form-item label="实际外径:">
                  <div class="equipment-item">{{ item.outerDiameter }}(mm)</div>
                </el-form-item>
                <el-form-item label="壁厚:">
                  <div class="equipment-item">{{ item.thickness }} (mm)</div>
                </el-form-item>
                <el-form-item label="实际内径:">
                  <div class="equipment-item">{{ item.innerDiameter }} (mm)</div>
                </el-form-item>
                 <el-form-item label="粗糙度:">
                  <label slot="label">粗糙度:</label>
                  <div class="equipment-item">{{ item.roughness }}</div>
                </el-form-item>
                <el-form-item label="埋深:">
                  <label slot="label">埋深:</label>
                  <div class="equipment-item">{{ item.depth }}(m)</div>
                </el-form-item>
                <el-form-item label="压力级别:">
                  <div class="equipment-item">{{ item.pressureLevelName }}</div>
                </el-form-item>
                 <el-form-item label="设计压力:">
                  <div class="equipment-item">{{ item.designPressure }}</div>
                </el-form-item>
                <el-form-item label="运行压力:">
                  <div class="equipment-item">{{ item.operationPressure }}</div>
                </el-form-item>
                <el-form-item label="管道长度:">
                  <div class="equipment-item">{{ item.pipeLength }} (m)</div>
                </el-form-item>
                <el-form-item label="建设日期:">
                  <div class="equipment-item">{{ item.buildDate }}</div>
                </el-form-item>
                <el-form-item label="燃气分类:">
                  <div class="equipment-item">{{ item.gasTypeName }}</div>
                </el-form-item>
                <el-form-item label="状态:">
                  <div class="equipment-item">{{ item.pipeStatusName }}</div>
                </el-form-item>
                <el-form-item label="敷设方式:">
                  <div class="equipment-item">{{ item.buildTypeName }}</div>
                </el-form-item>
               <el-form-item label="确认状态:">
                  <div class="equipment-item">{{ item.confirmStatusCodeName }}</div>
                </el-form-item>
                <el-form-item label="下次检测日期:" label-width="90px">
                  <div class="equipment-item" style="width:160px">{{ item.nextMonitorDate }}</div>
                </el-form-item>
                <el-form-item label="所在道路:">
                  <div class="equipment-item-textarea">{{ item.road }}</div>
                </el-form-item>
                <el-form-item label="建设单位:">
                  <div class="equipment-item-textarea">{{ item.buildOrganization }}</div>
                </el-form-item>
                <el-form-item label="设计单位:">
                <div class="equipment-item-textarea">{{ item.designOrganization }}</div>
              </el-form-item>
                
                <el-form-item label="备注:">
                  <label slot="label">备注:</label>
                  <div class="equipment-item-textarea">{{ item.remark }}</div>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer" v-show="$parent.gis">
             <el-button
                type="danger"
                size="mini"
                class="but"
                @click="equipmentDel(item.id, 'line')"
                >删 除</el-button
              >
              <el-button type="primary" class="but" size="mini" @click="equipmentSave(item, 'line')"
                >编 辑</el-button
              >
             
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'node'">
          <div class="markTitle">
            <p>
              <span>{{ item.title }}--{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass" label-position="right" label-width="80px">
                <el-form-item label="所属图层:">
                  <div class="equipment-item">
                    {{ item.layerName }}
                  </div>
                </el-form-item>
                <el-form-item label="节点名称:">
                  <div class="equipment-item">
                    {{ item.pointName }}
                  </div>
                </el-form-item>
                <el-form-item label="节点坐标:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="高程(m):">
                  <div class="equipment-item">
                    {{ item.elevation }}
                  </div>
                </el-form-item>
                <el-form-item label="埋深(m):">
                  <div class="equipment-item">
                    {{ item.depth }}
                  </div>
                </el-form-item>
                <el-form-item label="备注:">
                  <div class="equipment-item">
                    {{ item.remark }}
                  </div>
                </el-form-item>
                <el-form-item label="所在地址:">
                  <div class="equipment-item">
                    {{ item.address }}
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer" v-if="$parent.gis">
              <el-dropdown trigger="click" placement="top" @command="equipmentSave(item, $event)">
                <el-button class="but" size="mini">
                  转换设备<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" class="gis-default" style="width:100px">
                  <el-dropdown-item command="nodeToValve">阀门</el-dropdown-item>
                  <el-dropdown-item command="fittings">管件</el-dropdown-item>
                  <el-dropdown-item command="pipePoints">管线点</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="primary" class="but" size="mini" @click="equipmentSave(item, 'node')"
                >编 辑</el-button
              >
              <el-dropdown
                v-if="item.pipeLink.length > 0"
                trigger="click"
                placement="top"
                @command="selectPipe(item, $event)"
              >
                <el-button type="danger" class="but" size="mini">
                  删 除<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" class="gis-default">
                  <el-dropdown-item
                    command="0"
                    @mouseover.native="selectPipeLight(item.pipeLink[0].lineArray)"
                    @mouseleave.native="clearPipe()"
                  >
                    {{ item.pipeLink[0].pipeName + (item.pipeLink[0].pipeCode || '') }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    command="1"
                    @mouseover.native="selectPipeLight(item.pipeLink[1].lineArray)"
                    @mouseleave.native="clearPipe()"
                  >
                    {{ item.pipeLink[1].pipeName + (item.pipeLink[1].pipeCode || '') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'stationPoint'">
          <div class="markTitle">
            <p>
              <span>{{ item.title }}--{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass" label-position="right" label-width="80px">
                <el-form-item label="名称:">
                  <div class="equipment-item">
                    {{ item.pointName }}
                  </div>
                </el-form-item>
                <el-form-item label="类型:">
                  <div class="equipment-item">
                    {{ item.channelTypeName }}
                  </div>
                </el-form-item>
                <el-form-item label="场站名称:">
                  <div class="equipment-item">
                    <span class="textClick" @click="openStation(item.stationId)">{{
                      item.stationName
                    }}</span>
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="所在地址:">
                  <div class="equipment-item">
                    {{ item.address }}
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'monitoring'">
          <div class="markTitle">
            <p>
              <span>{{ item.pointName }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass"  label-position="right" label-width="85px" style="width:380px">
                <el-form-item label="所属图层:">
                  <div class="equipment-item-textarea">
                    {{ item.layerName }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item-textarea">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="所在地址:">
                  <div class="equipment-item-textarea">
                    {{ item.address }}
                  </div>
                </el-form-item>
                <div v-if="!$parent.gis">
                  
                  <el-row v-for="itemsb,index in item.eqs" :key="index" >
                    <el-divider></el-divider>
                    <el-form-item label="设备名称:" prop="type">
                      <span class="spanDesc">{{ itemsb.eqName}}</span>
                      <el-button type="text" @click="showDesc(itemsb.eqId,itemsb.eqTypeName)" style="margin-right: 10px;float: right;">查看详情</el-button>
                    </el-form-item>
                    
                </el-row>
                
                  <el-row  v-if="item.realtimeList&&item.realtimeList.length>0">
                    <el-divider></el-divider>
                    <el-form-item label="流量:" prop="type" label-width="40px">
                      <span class="spanDesc" v-for="iteml,index1 in item.realtimeList" :key="index1" :style="iteml.flowWarn==2?'color:red':''">{{iteml.flow}} <span v-if="index1!=item.realtimeList.length-1">,</span></span>
                     
                    </el-form-item>
                    <el-form-item label="压力:" prop="type" label-width="40px">
                      <span class="spanDesc" v-for="iteml,index1 in item.realtimeList" :key="index1" :style="iteml.pressureWarn==2?'color:red':''">{{iteml.pressure}} <span v-if="index1!=item.realtimeList.length-1">,</span></span>
                    </el-form-item>
                </el-row>
               </div>
              
              </el-form>
            </div>
            <div class="footer" v-if="$parent.gis">
              <el-button
                type="primary"
                class="but"
                size="mini"
                @click="equipmentSave(item, 'monitoring')"
              >
                编 辑
              </el-button>
              <el-button
                type="danger"
                class="but"
                size="mini"
                @click="equipmentDel(item.id, item.equipmentType)"
              >
                移 除
              </el-button>
              <el-button class="but" size="mini" @click="equipmentList(item, 'monitoring')">
                监测点设备
              </el-button>
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'valve'">
          <div class="markTitle">
            <p>
              <span>{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form
                class="blueFormLabelClass"
                label-position="right"
                :inline="true"
                style="width:580px"
                label-width="110px"
              >
                <el-form-item label="所属图层:">
                  <div class="equipment-item-textarea">{{ item.layerName }}</div>
                </el-form-item>
                <el-form-item label="设备编号:">
                  <div class="equipment-item-textarea">
                    {{ item.valveCode }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item-textarea">{{ item.point }}</div>
                </el-form-item>
                <el-form-item label="设备名称:">
                  <div class="equipment-item-textarea">{{ item.valveName }}</div>
                </el-form-item>
                <el-form-item label="阀门种类:">
                  <div class="equipment-item">{{ item.valveKindName }}</div>
                </el-form-item>
                <el-form-item label="敷设方式:">
                  <div class="equipment-item">{{ item.layTypeName }}</div>
                </el-form-item>
                <el-form-item label="阀门设施:">
                  <div class="equipment-item">{{ item.valveFacilitiesName }}</div>
                </el-form-item>
                <el-form-item label="阀井深度:"  v-if="item.valveFacilities =='2'">
                  <div class="equipment-item">{{ item.valveWellDepth }}</div>
                </el-form-item>
                <el-form-item label="阀井类型:"  v-if="item.valveFacilities =='2'">
                  <div class="equipment-item">{{ item.valveTypeName }}</div>
                </el-form-item>
                <el-form-item label="埋深(m):">
                  <div class="equipment-item">{{ item.depth }}</div>
                </el-form-item>
                <el-form-item label="规格:">
                  <div class="equipment-item">{{ item.spec }}</div>
                </el-form-item>
                <el-form-item label="型号:">
                  <div class="equipment-item">{{ item.model }}</div>
                </el-form-item>
                <el-form-item label="设备状态:">
                  <div class="equipment-item">{{ item.generalStatusName }}</div>
                </el-form-item>
                <el-form-item label="连接方式:">
                  <div class="equipment-item">{{ item.connectTypeName }}</div>
                </el-form-item>
               
                <el-form-item label="安装时间:">
                  <div class="equipment-item">{{ item.installDate }}</div>
                </el-form-item>
                <el-form-item label="附着压力级别:">
                  <div class="equipment-item">{{ item.pressureLevelName }}</div>
                </el-form-item>
                <el-form-item label="所在街道:">
                  <div class="equipment-item-textarea">{{ item.road }}</div>
                </el-form-item>
                <el-form-item label="生产厂家:">
                  <div class="equipment-item-textarea">{{ item.factory }}</div>
                </el-form-item>
                <el-form-item label="备注:">
                  <div class="equipment-item-textarea">{{ item.remark }}</div>
                </el-form-item>
                <el-form-item label="所在地址:">
                  <div class="equipment-item-textarea">
                    {{ item.address }}
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer" v-if="$parent.gis">
              
              <el-button
                type="danger"
                class="but"
                size="mini"
                @click="equipmentDel(item.pointId, item.equipmentType)"
              >
                移 除
              </el-button>
              <el-button
                type="primary"
                class="but"
                size="mini"
                @click="equipmentSave(item, item.equipmentType)"
              >
                编 辑
              </el-button>
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'fittings' || item.equipmentType === 'pipePoints'">
          <div class="markTitle">
            <p>
              <span>{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass" label-position="right" label-width="80px">
                <el-form-item label="名称:">
                  <div class="equipment-item">
                    {{ item.pointName}}
                  </div>
                </el-form-item>
                <el-form-item label="坐标:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="高程(m):">
                  <div class="equipment-item">
                    {{ item.elevation }}
                  </div>
                </el-form-item>
                <el-form-item label="埋深(m):">
                  <div class="equipment-item">
                    {{ item.depth }}
                  </div>
                </el-form-item>
                <el-form-item :label="item.equipmentType === 'fittings' ? '管件:' : '管线点:'">
                  <div class="equipment-item">
                    {{ item.nodeTypeName }}
                  </div>
                </el-form-item>
                <el-form-item label="备注:">
                  <div class="equipment-item">
                    {{ item.remark }}
                  </div>
                </el-form-item>
                <el-form-item :label="tpl.attributeName+':'" v-for="(tpl,index) in item.frees" :key="index">
                  <div class="equipment-item">
                    {{ tpl.freeValue }}
                  </div>
                </el-form-item>
                <el-form-item label="所在地址:">
                  <div class="equipment-item-textarea">
                    {{ item.address }}
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer" v-if="$parent.gis">
              
              <el-button
                type="danger"
                class="but"
                size="mini"
                @click="equipmentDel(item.id, item.equipmentType)"
                >移 除</el-button
              >
              <el-button
                type="primary"
                class="but"
                size="mini"
                @click="equipmentSave(item, item.equipmentType)"
                >编 辑</el-button
              >
            </div>
          </div>
        </div>
         <div v-if="item.equipmentType === 'hidden'">
          <div class="markTitle">
            <p>
              <span>{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass" label-position="right" label-width="60px"  size="mini">
                <el-form-item label="名称:">
                  <div class="equipment-item">
                    {{ item.name }}
                  </div>
                </el-form-item>
                <el-form-item label="坐标:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="埋深(m):">
                  <div class="equipment-item">
                    {{ item.depth }}
                  </div>
                </el-form-item>
               
                <el-form-item label="备注:">
                  <div class="equipment-item">
                    {{ item.remarks }}
                  </div>
                </el-form-item>
                <el-form-item label="工程照片:">
                  <div class="equipment-item">
                    <el-image 
                          style="width:80px;height:80px;margin-left:10px"
                          
                          :preview-src-list="item.urlList" :src="itemImg" v-for="itemImg,index in item.urlList" :key="index" >
                        </el-image>
                  
                  </div>
                </el-form-item>

              </el-form>
            </div>
            <div class="footer" v-if="$parent.gis">
              
              <el-button
                type="danger"
                class="but"
                size="mini"
                @click="equipmentDel(item.id, item.equipmentType)"
                >作 废</el-button
              >
              <el-button
                type="primary"
                class="but"
                size="mini"
                @click="editHidden(item, item.equipmentType)"
                >编 辑</el-button
              >
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'events'">
          <div class="markTitle">
            <p>
              <span>三方施工</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass" label-position="right" label-width="60px"  size="mini">
                <el-form-item label="事件类型:">
                  <div class="equipment-item">
                    {{item.eventName}}
                  </div>
                </el-form-item>
                <el-form-item label="详细地址:">
                  <div class="equipment-item">
                    {{ item.eventAddress }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
               
                <el-form-item label="说明:">
                  <div class="equipment-item">
                    {{ item.eventRemark }}
                  </div>
                </el-form-item>
                <el-form-item label="图片:">
                  <div class="equipment-item">
                    <el-image 
                          style="width:80px;height:80px;margin-left:10px"
                          
                          :preview-src-list="item.imgUrlList" :src="itemImg" v-for="itemImg,index in item.imgUrlList" :key="index" >
                        </el-image>
                   
                  </div>
                </el-form-item>
                <el-form-item label="附件:">
                  <div class="equipment-item">
                    <a :href="itemImg" target="_backe"  v-for="itemImg,index in item.fileUrlList" :key="index" >{{itemImg}}</a>
                    
                  </div>
                </el-form-item>

              </el-form>
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'facilityAccept'">
          <div class="markTitle">
            <p>
              <span>{{ item.layerName }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass" label-position="right" label-width="60px">
                <el-form-item label="重点设施:">
                  <div class="equipment-item">
                    {{item.typeName}}
                  </div>
                </el-form-item>
                <el-form-item label="所属图层:">
                  <div class="equipment-item">
                    {{ item.layerName ||('重点实施-'+item.typeName)}}
                  </div>
                </el-form-item>
                <el-form-item label="用户:">
                  <div class="equipment-item">
                    {{ item.userName }}
                  </div>
                </el-form-item>
               
                <el-form-item label="联系电话:">
                  <div class="equipment-item">
                    {{ item.phone }}
                  </div>
                </el-form-item>
                <el-form-item label="地址:">
                  <div class="equipment-item">
                    {{ item.address }}
                  </div>
                </el-form-item>
                <el-form-item label="照片:" >
                  <div class="equipment-item">
                     <el-image 
                          style="width:80px;height:80px;margin-left:10px"
                          
                          :preview-src-list="item.imgList" :src="itemImg" v-for="itemImg,index in item.imgList" :key="index" >
                        </el-image>
                   
                  </div>
                </el-form-item>

              </el-form>
            </div>
          </div>
        </div>
         <div v-if="item.equipmentType === 'markerCheck'">
          <div class="markTitle">
            <p>
              <span>{{ item.name }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form class="blueFormLabelClass" label-position="right" label-width="60px">
                
                <el-form-item label="所属图层:">
                  <div class="equipment-item">
                    {{ item.layerName }}
                  </div>
                </el-form-item>
                <el-form-item label="名称:">
                  <div class="equipment-item">
                    {{ item.name }}
                  </div>
                </el-form-item>
                <el-form-item label="埋深(m):">
                  <div class="equipment-item">
                    {{ item.depth }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:">
                  <div class="equipment-item">
                    {{ item.point }}
                  </div>
                </el-form-item>
                <el-form-item label="状态:">
                  <div class="equipment-item">
                    {{ item.statusCodeName }}
                  </div>
                </el-form-item>
               
                <el-form-item label="备注:">
                  <div class="equipment-item">
                    {{ item.remark }}
                  </div>
                </el-form-item>
                <el-form-item label="现场照片:">
                  <div class="equipment-item">
                      <el-image 
                          style="width:80px;height:80px;margin-left:10px"
                          
                          :preview-src-list="item.urlList" :src="itemImg" v-for="itemImg,index in item.urlList" :key="index" >
                        </el-image>
                    
                  </div>
                </el-form-item>

              </el-form>
            </div>
            <div class="footer" v-if="$parent.gis">
              
              <el-button
                type="danger"
                class="but"
                size="mini"
                @click="equipmentDel(item.id, item.equipmentType)"
                >移 除</el-button
              >
              <el-button
                type="primary"
                class="but"
                size="mini"
                @click="editHidden(item, item.equipmentType)"
                >编 辑</el-button
              >
            </div>
          </div>
        </div>
        <div v-if="item.equipmentType === 'changeLayer'">
          <div class="markTitle">
            <p>
              <span>{{ item.title }}</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form
                ref="layerForm"
                :model="item"
                :rules="rule"
                label-position="right"
                label-width="90px"
              >
                <el-form-item label="所属图层:">
                  <div class="equipment-item">
                    {{ item.nodeName }}
                  </div>
                </el-form-item>
                <el-form-item label="目标图层:" prop="newlayerId" size="mini">
                  <el-cascader
                    v-model="item.newlayerId"
                    :options="item.options"
                    :props="props"
                    placeholder="请选择图层"
                    popper-class="gis-default"
                    style="width:100%"
                  >
                    <template slot-scope="{ data }">
                      <span v-if="data.layerType == 1"
                        ><img src="../../image/tree-folder.png"
                      /></span>
                      <span v-else><img src="../../image/tree-file.png"/></span>
                      <span> {{ data.name }}</span>
                    </template>
                  </el-cascader>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer">
              <el-button class="but" size="mini" @click="close()">取 消</el-button>
              <el-button type="primary" class="but" size="mini" @click="changeLayer(item)"
                >保 存</el-button
              >
              
            </div>
          </div>
        </div>
        <div v-if="item.act === 'all'">
          <div class="markTitle">
            <p>
              <span>批量更换图层</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList">
              <el-form
                ref="layerForm"
                :model="item"
                :rules="rule"
                label-position="right"
                label-width="90px"
                size="mini"
              >
                <div style="height:25px"></div>
                <el-form-item label="目标图层:" prop="newlayerId">
                  <el-cascader
                    v-model="item.newlayerId"
                    :options="item.options"
                    :props="props"
                    placeholder="请选择图层"
                    popper-class="gis-default"
                    style="width:100%"
                  >
                    <template slot-scope="{ data }">
                      <span v-if="data.layerType == 1"
                        ><img src="../../image/tree-folder.png"
                      /></span>
                      <span v-else><img src="../../image/tree-file.png"/></span>
                      <span> {{ data.name }}</span>
                    </template>
                  </el-cascader>
                </el-form-item>
                <div style="height:18px"></div>
                <el-form-item label="更换元素:" prop="newlayerId">
                  <el-select v-model="updateAll" multiple placeholder="请选择" style="width:100%">
                    <el-option
                      v-for="item in optionsList1"
                      :key="item.dictCode"
                      :label="item.name"
                      :value="item.dictCode">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer">
              <el-button class="but" size="mini" @click="close()">取 消</el-button>
              <el-button type="primary" class="but" size="mini" @click="changeLayerAll(item)">保 存</el-button>
            </div>
          </div>
        </div>
        <div v-if="item.act === 'allDele'">
          <div class="markTitle">
            <p>
              <span>批量删除</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList" style="width:435px">
              <el-form
                ref="layerForm"
                :model="item"
                label-position="right"
                label-width="60px"
                size="mini"
                style="margin:0px 10px;"
              >
                <div style="height:18px"></div>
                <el-form-item label="删除图层:" >
                  <el-input v-model="item.newlayerName" disabled></el-input>
                </el-form-item>
                <div style="height:18px"></div>
                <el-form-item label="删除元素:" prop="newlayerId">
                  <el-select v-model="delAll" multiple placeholder="请选择" style="width:100%">
                    <el-option
                      v-for="item in optionsList"
                      :key="item.dictCode"
                      :label="item.name"
                      :value="item.dictCode">
                    </el-option>
                  </el-select>
                </el-form-item>
                <span style="font-size:12px;color:red">删除管线时，可能会将其关联点位上的管件、管线点一并删除，请知悉</span>
              </el-form>
            </div>
            <div class="footer">
              
              <el-button class="but" size="mini" @click="close()">取 消</el-button>
              <el-button type="primary" class="but" size="mini" @click="DeletLayerAll(item)">删 除</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.geometryType && item.geometryType != 'point'" class="del">
        <div class="drawDel" @click="drawDel(item.id)"><i class="el-icon-error"></i></div>
      </div>
      <!-- <span class="lj"><span class="tip"></span></span> -->
      <div v-if="item.geometryType == 'point'&&!item.qType" class="markbox">
        <div class="mainBox">
          <div class="markTitle">
            <p>
              <span>标注</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList" style="width:330px">
              <el-form label-position="right" label-width="80px" ref="pointAdd" :model="item" :rules="pointRules"  size="mini">
                
                <el-form-item label="标记类型:" prop="signType" style="margin-bottom:20px;margin-top:20px">
                  <el-select v-if="$parent.gis"
                      v-model="item.signType"
                      popper-class="gis-default"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in labelTypeList"
                        :key="item.dictCode"
                        :label="item.name"
                        :value="item.dictCode"
                      >
                      </el-option>
                  </el-select>
                  <div class="equipment-item" v-else>
                    {{ item.signTypeName }}
                  </div>
                </el-form-item>
                <el-form-item label="名称:" style="margin-bottom:20px;margin-top:20px">
                  <el-input  v-if="$parent.gis"
                    v-model="item.geometryName"
                    maxlength="10"
                    placeholder="请输入名称"
                    @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  ></el-input>
                  <div class="equipment-item" v-else>
                    {{ item.geometryName }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:" style="margin-bottom:20px">
                  <el-input v-if="$parent.gis"
                    v-model="item.geometry"
                    :readonly="true"
                    placeholder="请输入经纬度"
                  ></el-input>
                  <div class="equipment-item" v-else>
                    {{ item.geometry }}
                  </div>
                </el-form-item>
                <el-form-item label="备注:" style="margin-bottom:20px">
                  <el-input v-if="$parent.gis"
                    v-model="item.remark"
                    maxlength="200"
                    type="textarea"
                    autosize
                    placeholder="请输入内容"
                    @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  >
                  </el-input>
                  <div class="equipment-item" v-else>
                    {{ item.geometry }}
                  </div>
                </el-form-item>
                <el-form-item label="所在地址:" style="margin-bottom:20px" prop="address">
                  <el-input v-if="$parent.gis"
                    v-model="item.address"
                    maxlength="200"
                    type="textarea"
                    autosize
                    disabled
                    placeholder="请输入内容"
                    @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  >
                  </el-input>
                  <div class="equipment-item" v-else>
                    {{ item.geometry }}
                  </div>
                </el-form-item>
                <el-form-item label="图片:" label-width="90px">
                  <div>
                    <el-upload v-if="$parent.gis"
                      action="#"
                      list-type="picture-card"
                      :http-request="onFileList"
                      :on-remove="onDeleteFileList"
                      :before-remove="beforHFhandleChangeImgDel"
                      :file-list="fileList"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                      :limit="5"
                      :on-exceed="msgLimit"
                      :on-error="errorList"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                    </div>
                     <div class="equipment-item" v-if="!$parent.gis">
                      <el-image  
                          style="width:80px;height:80px;margin-left:10px"
                          
                          :preview-src-list="item.imgKeys" :src="itemImg" v-for="itemImg,index in item.imgKeys" :key="index" >
                        </el-image>
                    
                       </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer" v-if="$parent.gis">
              
              <el-button v-if="!item.id" size="mini" class="but" @click="close()">取 消</el-button>
              <el-button v-else type="danger" size="mini" class="but" @click="drawDel(item.id)"
                >删 除</el-button
              >
              <el-button type="primary" class="but" size="mini" @click="save()">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.geometryType == 'point'&&item.qType=='marker_check'" class="markbox">
        <div class="mainBox">
          <div class="markTitle">
            <p>
              <span>检测点</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList" style="width:330px">
              <el-form label-position="right" label-width="70px" ref="fromCheck" size="small" :model="item" :rules="safeRules">
                
                
                <el-form-item label="名称:" prop="name" style="margin-bottom:20px;margin-top:20px">
                  <el-input
                    v-model="item.name"
                    maxlength="40"
                    placeholder="请输入名称" @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  ></el-input>
                </el-form-item>
                <el-form-item label="埋深(m):" prop="depth" style="margin-bottom:20px;margin-top:20px">
                  <el-input
                    v-model="item.depth"
                    maxlength="10"
                    placeholder="请输入埋深" @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  ></el-input>
                </el-form-item>
                <el-form-item label="经纬度:" prop="geometry" style="margin-bottom:20px">
                  <el-input
                    v-model="item.geometry"
                    :readonly="true"
                    placeholder="请输入经纬度"
                  ></el-input>
                </el-form-item>
                <el-form-item label="状态:" prop="statusCode" style="margin-bottom:20px;margin-top:20px">
                  <el-select
                      v-model="item.statusCode"
                      popper-class="gis-default"
                      placeholder="请选择"
                      style="width:100%"
                    >
                      <el-option
                        v-for="item in statusList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      >
                      </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="备注:" style="margin-bottom:20px">
                  <el-input
                    v-model="item.remark"
                    maxlength="200"
                    type="textarea"
                    autosize
                    placeholder="请输入内容" @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="现场照片:" label-width="70px">
                  <div>
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :http-request="onFileList"
                      :on-remove="onDeleteFileList"
                      :before-remove="beforHFhandleChangeImgDel"
                      :file-list="fileList"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                      :limit="5"
                      :on-exceed="msgLimit"
                      :on-error="errorList"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer">
              
              <el-button  size="mini" class="but" @click="close()">取 消</el-button>
              <el-button type="primary" class="but" size="mini" @click="saveCheck()">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.geometryType == 'point'&&item.qType=='marker_hidden'"   class="markbox">
        <div class="mainBox">
          <div class="markTitle">
            <p>
              <span>隐蔽工程</span>
            </p>
            <i class="el-icon-error" @click="close()"></i>
          </div>

          <div class="Feature line-mark">
            <div class="contentList" style="width:330px">
              <el-form label-position="right" label-width="75px" ref="fromHidden" :model="item"  size="small" :rules="hiddenRules" >
                
                <el-form-item label="工程类型:" style="margin-bottom:20px;margin-top:20px">
                <el-cascader
                style="width:100%"
                v-model="item.typeIds"
                :options="typeIdList"
                :props = "{label:'name',value:'id'}"
                ></el-cascader>
                 

                </el-form-item>
                <el-form-item label="名称:" style="margin-bottom:20px;margin-top:20px" prop="name">
                  <el-input
                    v-model="item.name"
                    maxlength="10"
                    
                    placeholder="请输入名称" @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  ></el-input>
                </el-form-item>
                <el-form-item label="埋深(m):" prop="depth" style="margin-bottom:20px;margin-top:20px" >
                  <el-input
                    v-model="item.depth"
                    maxlength="10"
                    placeholder="请输入埋深" @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  ></el-input>
                </el-form-item>
                <el-form-item label="经纬度:" style="margin-bottom:20px" prop="geometry">
                  <el-input
                    v-model="item.geometry"
                    :readonly="true"
                    placeholder="请输入经纬度"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注:" style="margin-bottom:20px">
                  <el-input
                    v-model="item.remarks"
                    maxlength="200"
                    type="textarea"
                    autosize
                    placeholder="请输入内容" @blur="setMapStatus(true)"
                    @focus="setMapStatus(false)"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="现场照片:" label-width="90px">
                  <div>
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :http-request="onFileList"
                      :on-remove="onDeleteFileList"
                      :before-remove="beforHFhandleChangeImgDel"
                      :file-list="fileList"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                      :limit="5"
                      :on-exceed="msgLimit"
                      :on-error="errorList"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="footer">
             
              <el-button  size="mini" class="but" @click="close()">取 消</el-button>
             
               <el-button type="primary" class="but" size="mini" @click="save_hidden()">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import {
  saveMake,
  delMake,
  uploadImg,
  findById,
  uploadImgDelete,
  updateMake,
  findDictTree,updateDetailById,detectionPointSave,selectDictListByParentCode,workHiddenSave,workHiddenUpdateDetailById
} from '../../apis/commonType'
import { warningDelete, cameraDelete } from '../../apis/equipment'
import {
  pointDelete,
  monitorDelete,
  valveDelete,
  pipeDelete,
  removePointById,
  stationFindByIds,cancelWorkHidden,detectionPointDelete, toolbarChooseDelete
} from '../../apis/tool.js'
import Coverlayer from '../../apis/coverlayer'
import { commonLayer, constantDict,commonDict, serverMap } from '../../utils/dict.js'
import { queryFeatrue } from '../../apis/query'
import { timingSafeEqual } from 'crypto'


export default {
  components: {  },
  data() {
    return {
      item: { url: [],remark:'',signType:'',geometryName:''},
      ids: '',
      dialogVisible: false,
      dialogImageUrl: '',
      repairBeforFilefileList: [],
      fileList: [],
      hoverLine: null,
      rule: {
        newlayerId: { required: true, message: '请选择图层', trigger: 'change' }
      },
      props: {
        emitPath: false,
        label: 'name',
        value: 'id'
      },
      headerStyle: {
        color: '#fff',
        'background-color': '#244162',
        'border-bottom': 'rgba(110, 183, 227, 0.2) 1px solid'
      },
      labelTypeList:[],
      statusList:[{code:1,name:'未修复'},{code:2,name:'已修复'}],
      typeIdList:[],
      safeRules:{
        name:[{ required: true, message: '请填写监测点名称', trigger: 'blur' }],
        geometry:[{ required: true, message: '请输入经纬度', trigger: 'change' }],
        statusCode:[{ required: true, message: '请选择状态', trigger: 'change' }],
        depth: [
          {
            required: true,
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确格式,可保留2位小数',
            trigger: 'blur'
          }
        ]
        },
      hiddenRules:{
        name:[{ required: true, message: '请填写隐蔽工程名称', trigger: 'blur' }],
        geometry:[{ required: true, message: '请输入经纬度', trigger: 'change' }],
        depth: [
          {
            required: true,
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确格式,可保留2位小数',
            trigger: 'blur'
          }
        ]
        },
        lnglat:null,
    pointRules:{
      signType:[{ required: true, message: '请选择标记类型', trigger: 'change' }],
      address: [{ required: true, message: '请输入所在地址', trigger: 'blur' }],
    },
    optionsList:serverMap[1013],
    optionsList1:serverMap[1016],
    delAll:[],
    updateAll:[]
    }

  },
  mounted() {},

  methods: {
    setMapStatus(flag){
      this.$parent.map.setStatus({
        keyboardEnable: flag,
      })
    },
    initialize(e) {
      this.item = e.item
      
      this.infoWindow = e.infoWindow
      if(this.item.geometry&&!this.item.id){
        let that = this;
        var geocoder = new AMap.Geocoder();
        let lnglat1 = this.item.geometry.split(' ')
          geocoder.getAddress(lnglat1, function (status, result) {
            if (result.info === "OK") {
              that.$set(that.item,'address',result.regeocode.formattedAddress);
            }
          });
      }
       
    },
    openStation(stationId) {
      stationFindByIds({ id: stationId }).then(res => {
        if (res.data) {
          let equipmentType =
            res.data.stationType == constantDict.gongqizhan.dictCode ? 'station' : 'userSta'
          let obj = {
            geometryType: null,
            equipmentType: equipmentType,
            ...res.data
          }
          obj.point = res.data.point.split('(')[1].split(')')[0]
          this.$parent.$refs.stationInfo.openDialog(obj)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    //关闭
    close() {
      this.$parent.isClick = true
      if(this.infoWindow){
        this.infoWindow.close()
      }
      if (!this.item.id) {
        this.$parent.clearDraw()
      }
      this.fileList = []
      this.$parent.noclose = false
      if(this.$parent.gis){
        this.$parent.$refs.topTool.clearTopTool()
      }
      this.item = {}
      this.dialogVisible = false
      this.$parent.lineHighlightRemove()
      this.$parent.pointHighlightRemove()
      this.delAll = []
      this.updateAll = []
    },
    msgLimit(file, fileList) {
      this.$message.error('最多上传5张图片')
    },
    /*
     ****************************************************************************************************
     * 功能描述： 保存点的数据
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-20
     ****************************************************************************************************
     */
    save() {
      this.$refs.pointAdd.validate(valid=>{
        if(valid){
          let p = {
        ...this.item
      }
      p.imgKeys = this.item.imgKeys.join(',')
      p.layerId = this.$store.getters.currentLayer
      let that = this;
      if (p.id) {
        updateMake(p).then(res => {
          if (res.code == 200) {
            that.$message.success(res.msg)
            that.$parent.clearDraw()
            that.close()
            this.$parent.$refs.topTool.clearTopTool()
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        saveMake(p).then(res => {
          if (res.code == 200) {
            that.$message.success(res.msg)
            that.$parent.clearDraw()
            that.close()
            this.$parent.$refs.topTool.clearTopTool()
          } else {
            this.$message.error(res.msg)
          }
        })
      }
        }
      })
      
    },
    saveCheck(){
      this.$refs.fromCheck.validate(valid => {
        if (valid) {
          let p = {...this.item}
         // p.urlList = this.item.urlList;
          p.urlList = this.item.imgKeys;
          p.point = this.item.geometry
          let that = this
          if(p.id){
              updateDetailById(p).then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$parent.clearDraw()
                that.$eventBus.$emit('setFilter')
                that.close()
                this.$parent.$refs.topTool.clearTopTool()
              } else {
                this.$message.error(res.msg)
              }
            })
          }else{
            p.layerId = this.$store.getters.currentLayer
              detectionPointSave(p).then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$parent.clearDraw()
                that.$eventBus.$emit('setFilter')
                that.close()
                this.$parent.$refs.topTool.clearTopTool()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
          
        }
      })
    },
    save_hidden(){
      this.$refs.fromHidden.validate(valid => {
        
        if (valid) {
          let p = {}
          // let p = {...this.item}
         // p.urlList = this.item.urlList;
          p.urlList = this.item.imgKeys;
          p.point = this.item.geometry
          p.remark = this.item.remarks
          if(this.item.typeIds.length>0){
            p.typeId = this.item.typeIds[0]
            if(this.item.typeIds[1]){
              p.typeSubId = this.item.typeIds[1]
            }
          }
          p.name = this.item.name;
          p.depth = this.item.depth;
          p.remove = this.item.remove
          
          let that = this
          if(this.item.id){
            p.id = this.item.id
            p.layerId = this.item.layerId
              workHiddenUpdateDetailById(p).then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$parent.clearDraw()
                that.$eventBus.$emit('setFilter')
                that.close()
                this.$parent.$refs.topTool.clearTopTool()
              } else {
                this.$message.error(res.msg)
              }
            })
          }else{
            p.layerId = this.$store.getters.currentLayer
              workHiddenSave(p).then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$parent.clearDraw()
                that.$eventBus.$emit('setFilter')
                that.close()
                this.$parent.$refs.topTool.clearTopTool()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
          
        }
      })
    },
    /*
     ****************************************************************************************************
     * 功能描述： 显示设备的弹框
     ****************************************************************************************************
     */
    openEquipmentInfo(feat, lnglat) {
      console.log(feat,11111111111)
      if(feat.act=='all'||feat.act=='allDele'){
        this.$parent.isClick = false
      }
      
      this.lnglat = lnglat;
      let html = this.$refs.Info
      let infoWindow = new AMap.InfoWindow({
        position: lnglat,
        offset: new AMap.Pixel(10, -45),
        anchor: 'top-left',
        content: html
      })
      this.initialize({
        item: feat,
        infoWindow: infoWindow
      })
      infoWindow.open(this.$parent.map)
      if (this.$refs.layerForm) {
        this.$refs.layerForm.resetFields()
      }
    },

    /*
     ****************************************************************************************************
     * 功能描述： 保存初点外的数据
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-20
     ****************************************************************************************************
     */
    saveAdd(obj) {
      obj.layerId = this.$store.getters.currentLayer
      console.log(obj)
      if (
        (obj.geometryType == 'line' && obj.geometry.split(',').length <= 1) ||
        (obj.geometryType == 'polygon' && obj.geometry.split(',').length <= 3)
      ) {
        this.$parent.$refs.topTool.clearTopTool()
      } else {
        saveMake(obj)
          .then(res => {
            if (res.code == 200) {
              this.$message.success('保存成功')
              this.$parent.clearDraw()
              this.$parent.$refs.topTool.clearTopTool()
            } else {
            }
          })
          // .catch(err => {
          //   this.$message.error(err)
          // })
      }
    },
    editHidden(item,type){
      if(type=='hidden'){
        item.geometryType = 'point'
        item.qType = 'marker_hidden'
      }else{
        item.geometryType = 'point'
        item.qType = 'marker_check'
      }
      
      item.equipmentType = '';
      item.geometry = item.point
      if(item.typeSubId){
        item.typeIds = [item.typeId,item.typeSubId];
      }else{
        item.typeIds = [item.typeId];
      }
      
      if(item.remarks){
        item.remarks = item.remarks
      }
      if(item.urlList){
        item.imgKeys = item.urlList
        this.fileList = []
        item.urlList.forEach(el=>{
          this.fileList.push({url:el})
        })
      }else{
        item.imgKeys = []
        this.fileList = []
      }
       let that = this
      let code =commonDict[300034].code 
        
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          that.labelTypeList = res.data[commonDict[300034].code]
          
        }
      })
      selectDictListByParentCode({level:2,parentCode:'ybgc'}).then(res=>{
        that.typeIdList = res.data
      })
      this.openEquipmentInfo(item, this.lnglat)
    },
    /*
     ****************************************************************************************************
     * 功能描述： 显示弹框
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-20
     * @feat 传入数据对象
     * @lnglat 显示位置
     ****************************************************************************************************
     */
    openFeatrueInfo(feat, lnglat) {
      
      let that = this
      let code =
        commonDict[300034].code 
        

      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          that.labelTypeList = res.data[commonDict[300034].code]
          
        }
      })
      selectDictListByParentCode({level:2,parentCode:'ybgc'}).then(res=>{
        that.typeIdList = res.data
      })
      let html = this.$refs.Info
      let infoWindow = null
      if (feat.geometryType != 'point') {
        infoWindow = new AMap.InfoWindow({
          position: lnglat,
          offset: new AMap.Pixel(0, 0),
          anchor: 'top-left',
          content: html
        })
      } else {
        //获取详情
        if (feat.id) {
          findById({ id: feat.id }).then(res => {
            if (res.data) {
              if (res.data.imgKeys) {
                feat.imgKeys = res.data.imgKeys.split(',')
                feat.imgKeys.forEach(el=>{
                  this.fileList.push({url:el})
                })
              } else {
                feat.imgKeys = []
                this.fileList = []
              }
              
              if( res.data.signType){
                feat.signType = res.data.signType
              }else{
                feat.signType = ''
              }
              
               feat.signTypeName = res.data.signTypeName
               feat.address = res.data.address
            } else {
              this.$message.error('该点已经不存在,请刷新页面')
              return
            }
          })
        } else {
          this.fileList = []
           
        }
        infoWindow = new AMap.InfoWindow({
          position: lnglat,
          offset: new AMap.Pixel(10, -45),
          anchor: 'top-left',
          content: html
        })
      }
      if(feat.qType=='marker_hidden'){
        feat.typeIds = []
      }
      this.initialize({
        item: feat,
        infoWindow: infoWindow
      })
      infoWindow.open(this.$parent.map)
    },
    /*
     ****************************************************************************************************
     * 功能描述： 删除数据
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-20
     * @id 删除对象id
     ****************************************************************************************************
     */
    drawDel(id) {
      let that = this
      this.$confirm(`是否要删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmBtnClass',
        cancelButtonClass: 'cancelBtnClass',
        customClass: 'gis-default',
        type: 'warning'
      }).then(() => {
        delMake({ id: id }).then(res => {
          that.$message.success(res.msg)
          that.$parent.clearDraw()
          that.close()
          that.$parent.rectangleHighlightFRemove()
          that.$parent.circleHighlightRemove()
        })
      })
    },
    clearPipe() {
      if (this.hoverLine) {
        this.$parent.map.remove(this.hoverLine)
        this.hoverLine = null
      }
    },
    selectPipe(item, index) {
      let that = this
      let pipeLinks = item.pipeLink
      that
        .$confirm(
          '确认删除管线【' +
            pipeLinks[index].pipeName +
            (pipeLinks[index].pipeCode || '') +
            '】信息及附件的设备信息吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            confirmButtonClass: 'confirmBtnClass',
            cancelButtonClass: 'cancelBtnClass',
            customClass: 'gis-default',
            type: 'warning'
          }
        )
        .then(() => {
          let id = item.id
          let line1 = pipeLinks[0]
          let line2 = pipeLinks[1]
          let index1 = line1.startPointId == id ? 1 : 0
          let index2 = line2.startPointId == id ? 1 : 0
          let p1 = line1.lineArray[index1]
          let p2 = line2.lineArray[index2]
          var lnglat1 = new AMap.LngLat(p1[0], p1[1])
          var lnglat2 = new AMap.LngLat(p2[0], p2[1])
          let data = {
            id: id,
            line: p1[0] + ' ' + p1[1] + ',' + p2[0] + ' ' + p2[1],
            pipeId: index == 0 ? line2.id : line1.id,
            pipeLength: Math.round(lnglat1.distance(lnglat2)).toFixed(2)
          }
          pointDelete(data)
            .then(res => {
              that.$message.success(res.msg)
              that.$eventBus.$emit('setFilter')
              that.close()
              that.clearPipe()
            })
            .catch(e => {
             // that.$message.error(e.message)
              that.clearPipe()
            })
        })
        .catch(() => {
          that.clearPipe()
        })
    },
    selectPipeLight(path) {
      if (this.hoverLine) {
        this.hoverLine.setPath(path)
      } else {
        this.hoverLine = new AMap.Polyline({
          path: path,
          strokeColor: '#3ba1fc',
          strokeWeight: 4,
          strokeOpacity: 0.9,
          isOutline: true,
          outlineColor: '#ffffff',
          borderWeight: 1,
          zIndex: 180,
          bubble: true
        })
        this.$parent.map.add([this.hoverLine])
      }
    },
    equipmentDel(id, type) {
      let that = this
      let tipTitle = '确定移除该设备？'
      if (type == 'line') {
        tipTitle = '确定删除管线【' + this.item.pipeName + '】信息？'
      } else if (type == 'monitoring') {
        tipTitle = '确认移除该监测点及该监测点下的设备信息？'
      } else if (type == 'hidden'){
        tipTitle = '确认作废【'+this.item.typeName+'_'+this.item.name+'】信息吗？'
      }
      this.$confirm(tipTitle, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmBtnClass',
        cancelButtonClass: 'cancelBtnClass',
        customClass: 'gis-default',
        type: 'warning'
      }).then(() => {
        if (type === 'warn') {
          warningDelete({ id: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        } else if (type === 'camera') {
          cameraDelete({ id: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        } else if (type === 'fittings' || type === 'pipePoints') {
          removePointById({ id: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        } else if (type === 'node') {
          pointDelete({ id: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        } else if (type === 'monitoring') {
          monitorDelete({ id: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        } else if (type === 'valve') {
          valveDelete({ pointId: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        } else if (type === 'line') {
          pipeDelete({ id: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        }else if (type === 'hidden') {  
          cancelWorkHidden({ id: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        }else if (type === 'markerCheck') {
          detectionPointDelete({ id: id })
            .then(res => {
              that.$message.success(res.msg)
              this.$eventBus.$emit('setFilter')
              that.close()
            })
            // .catch(e => this.$message.error(e.message))
        }
      })
    },
    equipmentSave(item, type) {
      this.$parent.$refs.editFeature.editEquipment(item, type)
      this.infoWindow.close()
    },
    async DeletLayerAll(item){
      if(this.delAll.length<=0){
        this.$message.error('请选择要删除的元素！')
        return;
      }
      let layerName = this.$parent.layerName
      let pos = []
      item.line.forEach(element => {
        pos.push(element.lng + ' ' + element.lat)
      })
      pos.push(pos[0])
      let posStr = pos.join(',')
      let groupLayer = this.$store.getters.groupLayer
      let group = []
      if (groupLayer && groupLayer.length > 0) {
        groupLayer.forEach(item => {
          group.push(item.id)
        })
      }
      let layersVue1 = group.toString()

      let isLegendSelect = this.$store.getters.isLegendSelect
      var groupC = isLegendSelect.toString()

      let typeList ='' 
      if(groupC){
        if(layerName==commonLayer.pressure){
          typeList += ' and pressure_level not in (' + groupC + ')'
        }
        if(layerName==commonLayer.diameter){
          typeList += ' and diameter not in (' + groupC + ')'
        }
        if(layerName==commonLayer.state){
          typeList += ' and pipe_status not in (' + groupC + ')'
        }
        if(layerName==commonLayer.confirmstatus){
          typeList += ' and confirm_status_code not in (' + groupC + ')'
        }
         
      }
      let mark = this.$store.getters.sketchType
      let layer_id = [102]
      if(mark&&mark.length>0){
        if(mark.includes("point")){
          layer_id.push('100')
        }
        if(mark.includes("line")){
          layer_id.push('101')
        }
      }
      layer_id = layer_id.toString()
      let legendSelect = this.$store.getters.legendSelect
      if (legendSelect && legendSelect.length > 0) {
        typeList += ` and node_type in (${legendSelect.toString()},${constantDict.guanxian.dictCode},${layer_id})`
      } else {
        typeList += ` and node_type in (${constantDict.guanxian.dictCode},${layer_id})`
      }
       
      let data = {
        SERVICE: 'wfs',
        VERSION: '1.0.0',
        REQUEST: 'GetFeature',
        typeName: commonLayer.all_query,
        outputFormat: 'application/json',
        CQL_FILTER:
          ' layer_id in (' + layersVue1 + ') '+typeList +' and INTERSECTS(location,POLYGON((' + posStr + ')))'
      }
      let loading = this.$loading({
        lock: true,
        text: '正在查询，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      await queryFeatrue(data).then(res => {
        loading.close()
        if (res.totalFeatures > 0) {
          let obj = {
            pipeIds: [],
            partsIds: [],
            monitorPointIds: [],
            stationIds: [],
            sketchIds:[],
            hiddenIds:[],
            signDetectionPointIds:[],
            valveIds:[],
          }
          let obj1 = {
            stationIds1:[],
            stationIds2:[],
            partsIds22:[],
            partsIds23:[],
            partsIds24:[],
            partsIds25:[],
            partsIds_1:[],
            sketchIds101:[],
            sketchIds100:[]
          }
          let lineLength = 0;
          let  selectDel = this.delAll.toString()
          res.features.forEach(el=>{
            let data = el.properties
            let node_type = data.node_type
            let retu = this.delAll.find(ele => ele == node_type)
            if(retu){
              if(node_type=='102'){
                obj.pipeIds.push(data.id)
                lineLength += Number(data['length'])
              }
              if(node_type=='1'){
                obj.stationIds.push(data.id)
                obj1.stationIds1.push(data.id)
              }
              if(node_type=='2'){
                obj.stationIds.push(data.id)
                obj1.stationIds2.push(data.id)
              }

              if(node_type=='21'){
                obj.valveIds.push(data.id)
              }
              if(node_type=='28'){
                obj.hiddenIds.push(data.id)
              }
              if(node_type=='9000'){
                obj.monitorPointIds.push(data.id)
              }
              if(node_type=='26'){
                obj.signDetectionPointIds.push(data.id)
              }
             
              if(node_type=='22'){
                obj.partsIds.push(data.id)
                obj1.partsIds22.push(data.id)
              }
              if(node_type=='23'){
                obj.partsIds.push(data.id)
                obj1.partsIds23.push(data.id)
              }
              if(node_type=='24'){
                obj.partsIds.push(data.id)
                obj1.partsIds24.push(data.id)
              }
              if(node_type=='25'){
                obj.partsIds.push(data.id)
                obj1.partsIds25.push(data.id)
              }
              if(node_type=='-1'){
                obj.partsIds.push(data.id)
                obj1.partsIds_1.push(data.id)
              }
              if(node_type=='100'){
                obj.sketchIds.push(data.id)
                obj1.sketchIds100.push(data.id)
              }
              if(node_type=='101'){
                obj.sketchIds.push(data.id)
                obj1.sketchIds101.push(data.id)
              }

            }
          })
          let msg = []
          if(obj.pipeIds.length>0){
            msg.push('管线'+(lineLength/1000).toFixed(2)+'km')
          }
          if(obj1.stationIds1.length>0){
            msg.push('供气站'+obj1.stationIds1.length+'座')
          }
          if(obj1.stationIds2.length>0){
            msg.push('用户站'+obj1.stationIds2.length+'座')
          }
          if(obj.valveIds.length>0){
            msg.push('阀门'+obj.valveIds.length+'个')
          }
          if(obj.monitorPointIds.length>0){
            msg.push('监测点'+obj.monitorPointIds.length+'个')
          }
          if(obj.signDetectionPointIds.length>0){
            msg.push('检测点'+obj.signDetectionPointIds.length+'个')
          }
          if(obj.signDetectionPointIds.length>0){
            msg.push('隐蔽工程'+obj.hiddenIds.length+'个')
          }
          if(obj1.sketchIds101.length>0){
            msg.push('草图'+obj1.sketchIds101.length+'个')
          }
          if(obj1.partsIds22.length>0){
            msg.push('三通'+obj1.partsIds22.length+'个')
          }
          if(obj1.partsIds23.length>0){
            msg.push('变径'+obj1.partsIds23.length+'个')
          }
          if(obj1.partsIds24.length>0){
            msg.push('塑钢转换'+obj1.partsIds24.length+'个')
          }
          if(obj1.partsIds25.length>0){
            msg.push('其他管件'+obj1.partsIds25.length+'个')
          }
          if(obj1.partsIds_1.length>0){
            msg.push('管线点'+obj1.partsIds_1.length+'个')
          }
          if(obj1.sketchIds100.length>0){
            msg.push('标记'+obj1.sketchIds100.length+'个')
          }
          if(obj1.sketchIds101.length>0){
            msg.push('草图'+obj1.sketchIds101.length+'个')
          }
          if(msg.length>0){
            this.$confirm(msg.toString()+'是否确认删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'gis-default',
            type: 'warning',
            closeOnClickModal: false,
            distinguishCancelAndClose: true
          }).then(() => {
                toolbarChooseDelete(obj).then(res=>{
                  this.$message.success(res.msg)
                  this.$eventBus.$emit('setFilter')
                  this.$eventBus.$emit('setMarker')
                  this.close()
                })
          }).catch(action => {
            if (action == 'cancel') {
              this.close()
            }
          }) 
          }else{
            this.$message.error('暂无数据')
          }

             
        }else{
          this.$message.error('暂无数据')
        }   
       })
        
    },
    async changeLayerAll(item) {
      if (!item.newlayerId) {
        this.$message.error('请选择目标图层')
        return
      }
      if(this.updateAll.length<=0){
        this.$message.error('请选择要更换的元素！')
        return;
      }
      let pos = []
      item.line.forEach(element => {
        pos.push(element.lng + ' ' + element.lat)
      })
      pos.push(pos[0])
      let posStr = pos.join(',')
      let groupLayer = this.$store.getters.groupLayer
      let group = []
      if (groupLayer && groupLayer.length > 0) {
        groupLayer.forEach(item => {
          group.push(item.id)
        })
      }
      let layersVue1 = group.toString()

      // let legendSelect = this.$store.getters.legendSelect;
      // console.log(legendSelect)
      // let legendSelectArr = this.$store.getters.isLegendSelect
      // console.log(legendSelectArr)
      // let CQL_FILTER =  ' layer_id in (' + layersVue1 + ') and INTERSECTS(location,POLYGON((' + posStr + ')))';
      // legendSelect.push('102')
      // if(legendSelect&&legendSelect.length>0){
      //   CQL_FILTER +=" and node_type in ("+legendSelect.toString()+")"
      // }
      // let  setType = this.$parent.displaySettings
      // if(legendSelectArr&&legendSelectArr.length>0){
      //   if(setType=='40000'){
      //     CQL_FILTER +=" and pressure_level not in ("+legendSelectArr.toString()+")"
      //   }
      //   if(setType=='30010'){
      //     CQL_FILTER +=" and pipe_status not in ("+legendSelectArr.toString()+")"
      //   }
      //   if(setType=='30011'){
      //     CQL_FILTER +=" and confirm_status_code not in ("+legendSelectArr.toString()+")"
      //   }
      //   if(setType=='texture'){
      //     CQL_FILTER +=" and diameter not in ("+legendSelectArr.toString()+")"
      //   }
      // }
      let data = {
        SERVICE: 'wfs',
        VERSION: '1.0.0',
        REQUEST: 'GetFeature',
        typeName: commonLayer['all_query'],
        outputFormat: 'application/json',
        CQL_FILTER:' layer_id in (' + layersVue1 + ') and INTERSECTS(location,POLYGON((' + posStr + ')))'
      }
      const loading = this.$loading({
        lock: true,
        text: '正在修改，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      await queryFeatrue(data).then(res => {
        this.loading = false
        if (res.totalFeatures > 0) {
          let obj = {
            pipeIds: [],
            pointIds: [],
            monitorPointIds: [],
            warningIds: [],
            cameraIds: [],
            stationIds: [],
            sketchIds:[],
            eventsIds:[],
            facilityAcceptIds:[],
            hiddenIds:[],
            signDetectionPointIds:[]
          }
          res.features.forEach(element => {
            var el = element.properties
              console.log(el)
              console.log(this.updateAll)
              let retu = this.updateAll.find(ele => ele == el.node_type)
              if(retu){
                switch (el.type) {
                    
                    case 'line': {
                      obj.pipeIds.push(el.id)
                      break
                    }
                    case 'point': {
                      obj.pointIds.push(el.id)
                      break
                    }
                    case 'monitor': {
                      obj.monitorPointIds.push(el.id)
                      break
                    }
                    case 'warning': {
                      obj.warningIds.push(el.id)
                      break
                    }
                    case 'camera': {
                      obj.cameraIds.push(el.id)
                      break
                    }
                    case 'station': {
                      obj.stationIds.push(el.id)
                      break
                    }
                    case 'hidden': {
                      obj.hiddenIds.push(el.id)
                      break
                    }
                    case 'check': {
                      obj.signDetectionPointIds.push(el.id)
                      break
                    }
                    case 'events': {
                      obj.eventsIds.push(el.id)
                      break
                    }
                    case 'accept': {
                      obj.facilityAcceptIds .push(el.id)
                      break
                    }
                    case 'sketchIds': {
                      obj.sketchIds.push(el.id)
                      break
                    }
                    
                  }
              }
            
          })
          obj.layerId = item.newlayerId
          Coverlayer.layerChange(obj)
            .then(res => {
              if (res.code == 200) {
                this.$message.success(res.msg)
                loading.close()
                this.close()
                this.$eventBus.$emit('setFilter')
                this.$eventBus.$emit('setMarker')
              } else {
                this.$message.error(res.msg)
              }
            })
            // .catch(e => this.$message.error(e.message))
          loading.close()
        } else {
          this.$message.warning('暂无数据')
          loading.close()
        }
      })
      console.log(item)
    },
    changeLayer(item) {
      console.log(item)
      this.$refs.layerForm.validate(valid => {
        if (valid) {
          let obj = {
            layerId: item.newlayerId
          }
          switch (item.featureType) {
             case 'hidden': {
                obj.hiddenIds=[item.id]
                break
              }
              case 'check': {
                obj.signDetectionPointIds=[item.id]
                break
              }
              case '"eventsIds"': {
                obj.eventsIds=[item.id]
                break
              }
              case 'facilityAcceptIds': {
                obj.facilityAcceptIds=[item.id]
                break
              }
            case 'line': {
              obj.pipeIds = [item.id]
              break
            }
            case 'node': {
              obj.pointIds = [item.id]
              break
            }
            case 'monitor': {
              obj.monitorPointIds = [item.id]
              break
            }
            case 'warn': {
              obj.warningIds = [item.id]
              break
            }
            case 'camera': {
              obj.cameraIds = [item.id]
              break
            }
            case 'station': {
              obj.stationIds = [item.id]
              break
            }
          }
          Coverlayer.layerChange(obj)
            .then(res => {
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.close()
              } else {
                this.$message.error(res.msg)
              }
            })
            // .catch(e => this.$message.error(e.message))
        } else {
        }
      })
    },
    equipmentList(item) {
      this.$parent.$refs.editFeature.openEquipmentList(item)
      this.$parent.pointHighlightRemove()
      this.infoWindow.close()
    },
    /*
     ****************************************************************************************************
     * 功能描述：上传文件
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-23
     *
     ****************************************************************************************************
     */
    async onFileList(files) {
      let uploadObj = new FormData()
      uploadObj.append('file', files.file)
      uploadObj.append('modelName', '草图')
      await uploadImg(uploadObj).then(res => {
        // this.item.imgKeys.push(res.data.key)
        this.item.imgKeys.push(res.data.url)
      })
    },
    beforHFhandleChangeImgDel(file, fileList) {
      this.repairBeforFilefileList = fileList
      let index = this.getArrayIndex(this.repairBeforFilefileList, file)
      // console.log(this.item.imgKeys)
      // uploadImgDelete({ ids: this.item.imgKeys[index] }).then(res => {
        this.item.imgKeys.splice(index, 1)
      //})
    },
    getArrayIndex(arr, obj) {
      var i = arr.length
      while (i--) {
        if (arr[i] === obj) {
          return i
        }
      }
      return -1
    },
    onDeleteFileList(file) {},
    errorList() {
      this.$message.error('上传失败！')
    },
    showDesc(id,type){
      this.$parent.$refs.editFeature.openEQ(id, type)
      
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  width: 90px;
  height: 90px;
  line-height: 90px;
  background: none;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 90px;
  height: 90px;
}
::v-deep {
  .el-collapse-item__header {
    height: 30px;
    line-height: 30px;
  }
  .el-collapse {
    max-height: 300px;
    overflow: auto;
  }
}
.line-mark {
  width: 4rem;
}
.line-pipe {
  width: 8rem;
}
.bubbleBox {
  position: absolute;
  z-index: -1;
}
.Bubble {
  div {
    font-size: 14px;
    word-wrap: break-word;
  }
  position: relative;
  .lj {
    display: block;
    position: absolute;
    top: 0.5rem;
    left: -21px;
    transform: rotate(-90deg);
    z-index: 1;
    .tip {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #222;
      position: relative;
    }
    .tip:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -10px;
      left: -12px;
      z-index: -1;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid rgba(0, 204, 255, 0.5);
    }
  }
}
.markbox {
  background: var(--background-color-skin);
  // border: 1px solid #6eb7e3;
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  min-width: 356px;
  min-height: 180px;
  font-size: 18px;
  margin-left: 0.1rem;
  overflow: visible;
  position: relative;
  opacity: 0.98;
}
.equipment-item {
  text-align: left;
  width: 170px;
  color: var(--font-color-c2);
}
.equipment-item-textarea {
  text-align: left;
  width: 290px;
  line-height: 20px;
  display: inline-block;
  vertical-align: text-top;
}
.markTitle {
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: var(--background-title-skin);
   background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));

  p {
    display: flex;
    line-height: 1;
    justify-content: flex-start;
    align-items: center;
    margin-left: 21px;
    font-size: 14px;
    font-weight: bold;
  }
  i {
    font-size: 18px;
    color: #679db4;
    cursor: pointer;
    margin-right: 10px;
  }
  i:hover {
    color: #548aa1;
  }
}

.Feature {
  margin: 0;
  line-height: 0.28rem;
  text-align: left;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  ::v-deep .el-form-item{
    margin-bottom:0
  }
}
.contentList{
  padding:5px
}
.footer {
  // margin-top: 20px;
  text-align: right;
  padding: 10px 5px 20px 0;
}
.but {
  margin: auto 15px;
}

/* form-item下边距 */
.defaultTheme .el-form-item--small.el-form-item {
  margin-bottom: 2px;
}

/* table列表颜色 */
::v-deep .el-table {
  background-color: rgba(21, 38, 57, 1);
}
::v-deep .el-table td.el-table__cell {
  border-bottom: rgba(110, 183, 227, 0.2) 1px solid;
  color: #fff;
}

::v-deep .el-table__body tr > td {
  background-color: rgba(21, 38, 57, 1);
}
::v-deep .el-table__body tr:hover > td {
  background-color: rgba(21, 38, 57, 1);
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
::v-deep .hover-row {
  background: var(--background-hover-skin);
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: var(--background-hover-skin);
}

::v-deep .el-table__fixed-body-wrapper {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
::v-deep .el-table__fixed-right::before {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}

.textClick {
  color: #409eff;
  cursor: pointer;
}
.textClick:hover {
  color: #66b1ff;
}
// .blueFormLabelClass{
//   margin: 20px;
//   border-left: 1px solid var(--table-border-color);
//   border-right: 1px solid var(--table-border-color);
//   border-bottom: 1px solid var(--table-border-color);
// }
.blueFormLabelClass{
  margin-right: 20px;
  margin-left: 20px;
}
// ::v-deep .el-divider--horizontal{
//   margin: 2px 0px;
// }
// .blueFormLabelClass{
//   ::v-deep .el-form-item{margin-right: 0px;}
//   ::v-deep .el-form-item__label{border-top: 1px solid #BECBD2;border-right: 1px solid #BECBD2;background-color: #e7ebed;padding-right: 10px; }
//   ::v-deep .el-form-item__content{border-top: 1px solid #BECBD2;min-height: 30px;}
// }
::v-deep .el-form-item__content{color: var(--font-color-c2);}

</style>
